// Generated by purs version 0.15.15
import * as $foreign from "./foreign.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Nullable from "../Data.Nullable/index.js";
import * as Effect from "../Effect/index.js";
import * as Unsafe_Coerce from "../Unsafe.Coerce/index.js";
import * as Web_HTML_HTMLDocument_ReadyState from "../Web.HTML.HTMLDocument.ReadyState/index.js";
import * as Web_Internal_FFI from "../Web.Internal.FFI/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var toParentNode = Unsafe_Coerce.unsafeCoerce;
var toNonElementParentNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toDocument = Unsafe_Coerce.unsafeCoerce;
var readyState = /* #__PURE__ */ (function () {
    var $2 = map((function () {
        var $4 = Data_Maybe.fromMaybe(Web_HTML_HTMLDocument_ReadyState.Loading.value);
        return function ($5) {
            return $4(Web_HTML_HTMLDocument_ReadyState.parse($5));
        };
    })());
    return function ($3) {
        return $2($foreign["_readyState"]($3));
    };
})();
var head = /* #__PURE__ */ (function () {
    var $6 = map(Data_Nullable.toMaybe);
    return function ($7) {
        return $6($foreign["_head"]($7));
    };
})();
var fromParentNode = /* #__PURE__ */ Web_Internal_FFI.unsafeReadProtoTagged("HTMLDocument");
var fromNonElementParentNode = /* #__PURE__ */ Web_Internal_FFI.unsafeReadProtoTagged("HTMLDocument");
var fromNode = /* #__PURE__ */ Web_Internal_FFI.unsafeReadProtoTagged("HTMLDocument");
var fromEventTarget = /* #__PURE__ */ Web_Internal_FFI.unsafeReadProtoTagged("HTMLDocument");
var fromDocument = /* #__PURE__ */ Web_Internal_FFI.unsafeReadProtoTagged("HTMLDocument");
var documentElement = /* #__PURE__ */ (function () {
    var $8 = map(Data_Nullable.toMaybe);
    return function ($9) {
        return $8($foreign["_documentElement"]($9));
    };
})();
var currentScript = /* #__PURE__ */ (function () {
    var $10 = map(Data_Nullable.toMaybe);
    return function ($11) {
        return $10($foreign["_currentScript"]($11));
    };
})();
var body = /* #__PURE__ */ (function () {
    var $12 = map(Data_Nullable.toMaybe);
    return function ($13) {
        return $12($foreign["_body"]($13));
    };
})();
var activeElement = /* #__PURE__ */ (function () {
    var $14 = map(Data_Nullable.toMaybe);
    return function ($15) {
        return $14($foreign["_activeElement"]($15));
    };
})();
export {
    referrer,
    title,
    setTitle
} from "./foreign.js";
export {
    fromDocument,
    fromNode,
    fromParentNode,
    fromNonElementParentNode,
    fromEventTarget,
    toDocument,
    toNode,
    toParentNode,
    toNonElementParentNode,
    toEventTarget,
    documentElement,
    head,
    body,
    readyState,
    activeElement,
    currentScript
};
