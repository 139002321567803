// Generated by purs version 0.15.15
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Monad_Free from "../Control.Monad.Free/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
var resume = /* #__PURE__ */ Control_Monad_Free.resume(Concur_Core_Types.functorWidgetStep);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var map = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var dischargePartialEffect = function (dictMonoid) {
    var mempty = Data_Monoid.mempty(dictMonoid);
    return function (w) {
        var v = resume(Concur_Core_Types.unWidget(w));
        if (v instanceof Data_Either.Right) {
            return pure(new Data_Tuple.Tuple(w, mempty));
        };
        if (v instanceof Data_Either.Left && v.value0 instanceof Concur_Core_Types.WidgetStepEff) {
            return function __do() {
                var w$prime = v.value0.value0();
                return dischargePartialEffect(dictMonoid)(w$prime)();
            };
        };
        if (v instanceof Data_Either.Left && v.value0 instanceof Concur_Core_Types.WidgetStepView) {
            return pure(new Data_Tuple.Tuple(Control_Monad_Free.wrap(new Concur_Core_Types.WidgetStepView(v.value0.value0)), v.value0.value0.view));
        };
        throw new Error("Failed pattern match at Concur.Core.Discharge (line 40, column 28 - line 45, column 87): " + [ v.constructor.name ]);
    };
};
var discharge = function (dictMonoid) {
    var mempty = Data_Monoid.mempty(dictMonoid);
    return function (handler) {
        return function (v) {
            var v1 = resume(v);
            if (v1 instanceof Data_Either.Right) {
                return pure(mempty);
            };
            if (v1 instanceof Data_Either.Left && v1.value0 instanceof Concur_Core_Types.WidgetStepEff) {
                return function __do() {
                    var w$prime = v1.value0.value0();
                    return discharge(dictMonoid)(handler)(w$prime)();
                };
            };
            if (v1 instanceof Data_Either.Left && v1.value0 instanceof Concur_Core_Types.WidgetStepView) {
                return function __do() {
                    Effect_Aff.runAff_((function () {
                        var $28 = map(Concur_Core_Types.Widget);
                        return function ($29) {
                            return handler($28($29));
                        };
                    })())(v1.value0.value0.cont)();
                    return v1.value0.value0.view;
                };
            };
            throw new Error("Failed pattern match at Concur.Core.Discharge (line 24, column 32 - line 31, column 19): " + [ v1.constructor.name ]);
        };
    };
};
export {
    discharge,
    dischargePartialEffect
};
