// Generated by purs version 0.15.15
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Monad_RWS_Trans from "../Control.Monad.RWS.Trans/index.js";
import * as Control_Monad_Reader_Trans from "../Control.Monad.Reader.Trans/index.js";
import * as Control_Monad_State_Trans from "../Control.Monad.State.Trans/index.js";
import * as Control_Monad_Writer_Trans from "../Control.Monad.Writer.Trans/index.js";
import * as Control_Plus from "../Control.Plus/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_Foldable.foldableArray);
var orr = function (dict) {
    return dict.orr;
};
var readerMultiAlternative = function (dictMultiAlternative) {
    var orr1 = orr(dictMultiAlternative);
    var plusReaderT = Control_Monad_Reader_Trans.plusReaderT(dictMultiAlternative.Plus0());
    return {
        orr: function (rs) {
            return function (r) {
                return orr1(map(function (m) {
                    return unwrap(m)(r);
                })(rs));
            };
        },
        Plus0: function () {
            return plusReaderT;
        }
    };
};
var rwsMultiAlternative = function (dictMultiAlternative) {
    var orr1 = orr(dictMultiAlternative);
    var plusRWST = Control_Monad_RWS_Trans.plusRWST(dictMultiAlternative.Plus0());
    return {
        orr: function (rs) {
            return function (r) {
                return function (s) {
                    return orr1(map(function (m) {
                        return unwrap(m)(r)(s);
                    })(rs));
                };
            };
        },
        Plus0: function () {
            return plusRWST;
        }
    };
};
var stateMultiAlternative = function (dictMultiAlternative) {
    var orr1 = orr(dictMultiAlternative);
    var Plus0 = dictMultiAlternative.Plus0();
    return function (dictMonad) {
        var plusStateT = Control_Monad_State_Trans.plusStateT(dictMonad)(Plus0);
        return {
            orr: function (rs) {
                return function (s) {
                    return orr1(map(function (m) {
                        return unwrap(m)(s);
                    })(rs));
                };
            },
            Plus0: function () {
                return plusStateT;
            }
        };
    };
};
var writerMultiAlternative = function (dictMultiAlternative) {
    var orr1 = orr(dictMultiAlternative);
    var plusWriterT = Control_Monad_Writer_Trans.plusWriterT(dictMultiAlternative.Plus0());
    return {
        orr: function (rs) {
            return orr1(map(unwrap)(rs));
        },
        Plus0: function () {
            return plusWriterT;
        }
    };
};
var defaultOrr = function (dictPlus) {
    var alt = Control_Alt.alt(dictPlus.Alt0());
    var empty = Control_Plus.empty(dictPlus);
    return function (xs) {
        return foldl(alt)(empty)(xs);
    };
};
var exceptMultiAlternative = function (dictMonoid) {
    var plusExceptT = Control_Monad_Except_Trans.plusExceptT(dictMonoid);
    return function (dictMultiAlternative) {
        return function (dictMonad) {
            var plusExceptT1 = plusExceptT(dictMonad);
            return {
                orr: defaultOrr(plusExceptT1),
                Plus0: function () {
                    return plusExceptT1;
                }
            };
        };
    };
};
export {
    orr,
    defaultOrr,
    exceptMultiAlternative,
    rwsMultiAlternative,
    readerMultiAlternative,
    stateMultiAlternative,
    writerMultiAlternative
};
