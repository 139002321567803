// Generated by purs version 0.15.15
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_Run from "../Concur.React.Run/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Foreign from "../Foreign/index.js";
import * as Routing_PushState from "../Routing.PushState/index.js";
import * as TomWellsOrg_Blog from "../TomWellsOrg.Blog/index.js";
import * as TomWellsOrg_Domain from "../TomWellsOrg.Domain/index.js";
import * as TomWellsOrg_Functions from "../TomWellsOrg.Functions/index.js";
import * as TomWellsOrg_GoogleAnalytics from "../TomWellsOrg.GoogleAnalytics/index.js";
import * as TomWellsOrg_Highlight from "../TomWellsOrg.Highlight/index.js";
import * as TomWellsOrg_Nav from "../TomWellsOrg.Nav/index.js";
import * as TomWellsOrg_Render from "../TomWellsOrg.Render/index.js";
import * as TomWellsOrg_Stream from "../TomWellsOrg.Stream/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Concur_Core_Types.widgetBind);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Concur_Core_Types.widgetMonadEff(Data_Monoid.monoidArray));
var routeToPage = function (v) {
    if (v instanceof TomWellsOrg_Nav.BlogSummary) {
        return new TomWellsOrg_Domain.ListOfArticles(TomWellsOrg_Blog.articles);
    };
    if (v instanceof TomWellsOrg_Nav.BlogArticle) {
        var found = TomWellsOrg_Functions.fuzzyFindArticleBySlug(TomWellsOrg_Blog.articles)(v.value0);
        return Data_Maybe.maybe(TomWellsOrg_Domain.NotFound.value)(TomWellsOrg_Domain.SingleArticle.create)(found);
    };
    if (v instanceof TomWellsOrg_Nav.Guestbook) {
        return TomWellsOrg_Domain.Guestbook.value;
    };
    if (v instanceof TomWellsOrg_Nav.Stream) {
        return new TomWellsOrg_Domain.Stream(TomWellsOrg_Stream.content);
    };
    if (v instanceof TomWellsOrg_Nav.Resume) {
        return TomWellsOrg_Domain.CV.value;
    };
    if (v instanceof TomWellsOrg_Nav.Contact) {
        return TomWellsOrg_Domain.Contact.value;
    };
    if (v instanceof TomWellsOrg_Nav.NotFound) {
        return TomWellsOrg_Domain.NotFound.value;
    };
    throw new Error("Failed pattern match at TomWellsOrg.Main (line 22, column 1 - line 22, column 34): " + [ v.constructor.name ]);
};
var pageToRoute = function (v) {
    if (v instanceof TomWellsOrg_Domain.ListOfArticles) {
        return TomWellsOrg_Nav.BlogSummary.value;
    };
    if (v instanceof TomWellsOrg_Domain.SingleArticle) {
        return new TomWellsOrg_Nav.BlogArticle(v.value0.slug);
    };
    if (v instanceof TomWellsOrg_Domain.Guestbook) {
        return TomWellsOrg_Nav.Guestbook.value;
    };
    if (v instanceof TomWellsOrg_Domain.Stream) {
        return TomWellsOrg_Nav.Stream.value;
    };
    if (v instanceof TomWellsOrg_Domain.CV) {
        return TomWellsOrg_Nav.Resume.value;
    };
    if (v instanceof TomWellsOrg_Domain.Contact) {
        return TomWellsOrg_Nav.Contact.value;
    };
    if (v instanceof TomWellsOrg_Domain.NotFound) {
        return TomWellsOrg_Nav.NotFound.value;
    };
    throw new Error("Failed pattern match at TomWellsOrg.Main (line 35, column 1 - line 35, column 34): " + [ v.constructor.name ]);
};
var actionHandler = function (navInterface) {
    return function (component) {
        return bind(component)(function (action) {
            return bind(liftEffect(navInterface.pushState(Foreign.unsafeToForeign({}))(TomWellsOrg_Nav.printRoute(pageToRoute(action.value0)))))(function () {
                return actionHandler(navInterface)(component);
            });
        });
    };
};
var main = function __do() {
    var navInterface = Routing_PushState.makeInterface();
    navInterface.listen(function (location) {
        var newRoute = TomWellsOrg_Nav.parseRoute(location.pathname);
        return function __do() {
            var v = (function __do() {
                var v = Concur_React_Run.runWidgetInDom("root")(actionHandler(navInterface)(TomWellsOrg_Render.withNavbar(TomWellsOrg_Render.renderPage(routeToPage(newRoute)))))();
                return TomWellsOrg_Highlight.applyHighlighting(Data_Unit.unit)();
            })();
            return TomWellsOrg_GoogleAnalytics.track(TomWellsOrg_Nav.printRoute(newRoute))();
        };
    })();
    var route = TomWellsOrg_Nav.currentRoute();
    var v = (function __do() {
        var v = Concur_React_Run.runWidgetInDom("root")(actionHandler(navInterface)(TomWellsOrg_Render.withNavbar(TomWellsOrg_Render.renderPage(routeToPage(route)))))();
        return TomWellsOrg_Highlight.applyHighlighting(Data_Unit.unit)();
    })();
    return TomWellsOrg_GoogleAnalytics.track(TomWellsOrg_Nav.printRoute(route))();
};
export {
    routeToPage,
    pageToRoute,
    actionHandler,
    main
};
