// Generated by purs version 0.15.15
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Ref from "../Effect.Ref/index.js";
import * as Routing from "../Routing/index.js";
import * as Web_DOM_Document from "../Web.DOM.Document/index.js";
import * as Web_DOM_MutationObserver from "../Web.DOM.MutationObserver/index.js";
import * as Web_DOM_Node from "../Web.DOM.Node/index.js";
import * as Web_DOM_Text from "../Web.DOM.Text/index.js";
import * as Web_Event_EventTarget from "../Web.Event.EventTarget/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Event_PopStateEvent_EventTypes from "../Web.HTML.Event.PopStateEvent.EventTypes/index.js";
import * as Web_HTML_HTMLDocument from "../Web.HTML.HTMLDocument/index.js";
import * as Web_HTML_History from "../Web.HTML.History/index.js";
import * as Web_HTML_Location from "../Web.HTML.Location/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Effect.bindEffect);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var add = /* #__PURE__ */ Data_Semiring.add(Data_Semiring.semiringInt);
var observe = /* #__PURE__ */ Web_DOM_MutationObserver.observe();
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var traverse_ = /* #__PURE__ */ Data_Foldable.traverse_(Effect.applicativeEffect);
var traverse_1 = /* #__PURE__ */ traverse_(Data_Foldable.foldableEither);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Effect.bindEffect);
var traverse_2 = /* #__PURE__ */ traverse_(Data_Map_Internal.foldableMap);
var insert = /* #__PURE__ */ Data_Map_Internal.insert(Data_Ord.ordInt);
var $$delete = /* #__PURE__ */ Data_Map_Internal["delete"](Data_Ord.ordInt);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Effect.functorEffect);
var makeImmediate = function (run) {
    return function __do() {
        var document = bind(Web_HTML.window)((function () {
            var $28 = map(Web_HTML_HTMLDocument.toDocument);
            return function ($29) {
                return $28(Web_HTML_Window.document($29));
            };
        })())();
        var nextTick = Effect_Ref["new"](new Data_Either.Right(0))();
        var obsvNode = map(Web_DOM_Text.toNode)(Web_DOM_Document.createTextNode("")(document))();
        var observer = Web_DOM_MutationObserver.mutationObserver(function (v) {
            return function (v1) {
                return function __do() {
                    Effect_Ref.modify_(Data_Either.either((function () {
                        var $30 = add(1);
                        return function ($31) {
                            return Data_Either.Right.create($30($31));
                        };
                    })())(Data_Either.Right.create))(nextTick)();
                    return run();
                };
            };
        })();
        observe(obsvNode)({
            characterData: true
        })(observer)();
        return bind(Effect_Ref.read(nextTick))(traverse_1(function (tick) {
            return function __do() {
                Effect_Ref.write(new Data_Either.Left(tick + 1 | 0))(nextTick)();
                return Web_DOM_Node.setNodeValue(show(tick))(obsvNode)();
            };
        }));
    };
};
var makeInterface = function __do() {
    var freshRef = Effect_Ref["new"](0)();
    var listenersRef = Effect_Ref["new"](Data_Map_Internal.empty)();
    var notify = function (ev) {
        return bindFlipped(traverse_2(function (v) {
            return v(ev);
        }))(Effect_Ref.read(listenersRef));
    };
    var locationState = function __do() {
        var loc = bind(Web_HTML.window)(Web_HTML_Window.location)();
        var state = bind(bind(Web_HTML.window)(Web_HTML_Window.history))(Web_HTML_History.state)();
        var pathname = Web_HTML_Location.pathname(loc)();
        var search = Web_HTML_Location.search(loc)();
        var hash = Web_HTML_Location.hash(loc)();
        var path = pathname + (search + hash);
        return {
            state: state,
            pathname: pathname,
            search: search,
            hash: hash,
            path: path
        };
    };
    var listen = function (k) {
        return function __do() {
            var fresh = Effect_Ref.read(freshRef)();
            Effect_Ref.write(fresh + 1 | 0)(freshRef)();
            Effect_Ref.modify_(insert(fresh)(k))(listenersRef)();
            return Effect_Ref.modify_($$delete(fresh))(listenersRef);
        };
    };
    var schedule = makeImmediate(bindFlipped(notify)(locationState))();
    var stateFn = function (op) {
        return function (state) {
            return function (path) {
                return function __do() {
                    bind(bind(Web_HTML.window)(Web_HTML_Window.history))(op(state)("")(path))();
                    return schedule();
                };
            };
        };
    };
    var listener = Web_Event_EventTarget.eventListener(function (v) {
        return bindFlipped(notify)(locationState);
    })();
    bind(Web_HTML.window)((function () {
        var $32 = Web_Event_EventTarget.addEventListener(Web_HTML_Event_PopStateEvent_EventTypes.popstate)(listener)(false);
        return function ($33) {
            return $32(Web_HTML_Window.toEventTarget($33));
        };
    })())();
    return {
        pushState: stateFn(Web_HTML_History.pushState),
        replaceState: stateFn(Web_HTML_History.replaceState),
        locationState: locationState,
        listen: listen
    };
};
var foldLocations = function (cb) {
    return function (init) {
        return function (psi) {
            return function __do() {
                var ref = bindFlipped(Effect_Ref["new"])(bindFlipped(init)(psi.locationState))();
                return psi.listen(function (loc) {
                    return bindFlipped(Data_Function.flip(Effect_Ref.write)(ref))(bindFlipped(Data_Function.flip(cb)(loc))(Effect_Ref.read(ref)));
                })();
            };
        };
    };
};
var foldPaths = function (cb) {
    return function (init) {
        return foldLocations(function (a) {
            var $34 = cb(a);
            return function ($35) {
                return $34((function (v) {
                    return v.path;
                })($35));
            };
        })(function ($36) {
            return init((function (v) {
                return v.path;
            })($36));
        });
    };
};
var matchesWith = function (dictFoldable) {
    var indexl = Data_Foldable.indexl(dictFoldable);
    return function (parser) {
        return function (cb) {
            var go = function (a) {
                var $37 = Data_Maybe.maybe(pure(a))(function (b) {
                    return voidRight(new Data_Maybe.Just(b))(cb(a)(b));
                });
                var $38 = indexl(0);
                return function ($39) {
                    return $37($38(parser($39)));
                };
            };
            return foldPaths(go)(go(Data_Maybe.Nothing.value));
        };
    };
};
var matches = /* #__PURE__ */ (function () {
    var $40 = matchesWith(Data_Foldable.foldableEither);
    return function ($41) {
        return $40(Routing.match($41));
    };
})();
var paths = /* #__PURE__ */ (function () {
    return matchesWith(Data_Foldable.foldableMaybe)(Data_Maybe.Just.create);
})();
var locations = function (cb) {
    var go = function (a) {
        return function (b) {
            return voidRight(new Data_Maybe.Just(b))(cb(a)(b));
        };
    };
    return foldLocations(go)(go(Data_Maybe.Nothing.value));
};
export {
    makeInterface,
    foldLocations,
    locations,
    foldPaths,
    paths,
    matches,
    matchesWith
};
