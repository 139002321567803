// Generated by purs version 0.15.15
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Semiring_Free from "../Data.Semiring.Free/index.js";
import * as Data_String_NonEmpty_Internal from "../Data.String.NonEmpty.Internal/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Data_Validation_Semiring from "../Data.Validation.Semiring/index.js";
import * as Routing_Match_Error from "../Routing.Match.Error/index.js";
import * as Routing_Types from "../Routing.Types/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree));
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_List_Types.foldableList);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_List_Types.bindList);
var map = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Data_List_Types.applicativeList);
var lookup = /* #__PURE__ */ Data_Map_Internal.lookup(Data_Ord.ordString);
var $$delete = /* #__PURE__ */ Data_Map_Internal["delete"](Data_Ord.ordString);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Tuple.functorTuple);
var mul = /* #__PURE__ */ Data_Semiring.mul(Data_Semiring_Free.semiringFree);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var one = /* #__PURE__ */ Data_Semiring.one(Data_Semiring_Free.semiringFree);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Data_Validation_Semiring.altV(Data_Semiring_Free.semiringFree));
var Match = function (x) {
    return x;
};
var str = function (route) {
    if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path) {
        return pure(new Data_Tuple.Tuple(route.value1, route.value0.value0));
    };
    return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedString.value));
};
var runMatch = function (v) {
    return function (route) {
        var foldErrors = function (errs) {
            return new Data_Either.Left(foldl(function (b) {
                return function (a) {
                    return a + ("\x0a" + b);
                };
            })("")(bind(map(Data_List.reverse)(unwrap(errs)))(function (es) {
                return pure1(foldl(function (b) {
                    return function (a) {
                        return a + (";" + b);
                    };
                })("")(map(Routing_Match_Error.showMatchError)(es)));
            })));
        };
        return Data_Validation_Semiring.validation(foldErrors)(function ($109) {
            return Data_Either.Right.create(Data_Tuple.snd($109));
        })(v(route));
    };
};
var params = function (route) {
    if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Query) {
        return pure(new Data_Tuple.Tuple(route.value1, route.value0.value0));
    };
    return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedQuery.value));
};
var param = function (key) {
    return function (route) {
        if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Query) {
            var v = lookup(key)(route.value0.value0);
            if (v instanceof Data_Maybe.Nothing) {
                return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(new Routing_Match_Error.KeyNotFound(key)));
            };
            if (v instanceof Data_Maybe.Just) {
                var remainingParams = $$delete(key)(route.value0.value0);
                return pure((function () {
                    var $50 = Data_Map_Internal.isEmpty(remainingParams);
                    if ($50) {
                        return new Data_Tuple.Tuple(route.value1, v.value0);
                    };
                    return new Data_Tuple.Tuple(new Data_List_Types.Cons(new Routing_Types.Query(remainingParams), route.value1), v.value0);
                })());
            };
            throw new Error("Failed pattern match at Routing.Match (line 118, column 7 - line 125, column 60): " + [ v.constructor.name ]);
        };
        return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedQuery.value));
    };
};
var optionalMatch = function (v) {
    return function (route) {
        return Data_Validation_Semiring.validation(Data_Function["const"](pure(new Data_Tuple.Tuple(route, Data_Maybe.Nothing.value))))((function () {
            var $110 = map1(Data_Maybe.Just.create);
            return function ($111) {
                return pure($110($111));
            };
        })())(v(route));
    };
};
var num = function (route) {
    if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path) {
        var v = Data_Number.fromString(route.value0.value0);
        if (v instanceof Data_Maybe.Just && !Data_Number["isNaN"](v.value0)) {
            return pure(new Data_Tuple.Tuple(route.value1, v.value0));
        };
        return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedNumber.value));
    };
    return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedNumber.value));
};
var newtypeMatch = {
    Coercible0: function () {
        return undefined;
    }
};
var matchFunctor = {
    map: function (fn) {
        return function (v) {
            return function (r) {
                return Data_Validation_Semiring.validation(Data_Validation_Semiring.invalid)(function (v1) {
                    return pure(new Data_Tuple.Tuple(v1.value0, fn(v1.value1)));
                })(v(r));
            };
        };
    }
};
var matchApply = {
    apply: function (v) {
        return function (v1) {
            var processFnRes = function (v2) {
                return Data_Validation_Semiring.validation(Data_Validation_Semiring.invalid)(function (v3) {
                    return pure(new Data_Tuple.Tuple(v3.value0, v2.value1(v3.value1)));
                })(v1(v2.value0));
            };
            var processFnErr = function (r) {
                return function (err) {
                    return Data_Validation_Semiring.invalid(mul(err)(Data_Validation_Semiring.validation(identity)(Data_Function["const"](one))(v1(r))));
                };
            };
            return function (r) {
                return Data_Validation_Semiring.validation(processFnErr(r))(processFnRes)(v(r));
            };
        };
    },
    Functor0: function () {
        return matchFunctor;
    }
};
var matchApplicative = {
    pure: function (a) {
        return function (r) {
            return pure(new Data_Tuple.Tuple(r, a));
        };
    },
    Apply0: function () {
        return matchApply;
    }
};
var matchAlt = {
    alt: function (v) {
        return function (v1) {
            return function (r) {
                return alt(v(r))(v1(r));
            };
        };
    },
    Functor0: function () {
        return matchFunctor;
    }
};
var matchPlus = {
    empty: /* #__PURE__ */ Data_Function["const"](/* #__PURE__ */ Data_Validation_Semiring.invalid(one)),
    Alt0: function () {
        return matchAlt;
    }
};
var matchAlternative = {
    Applicative0: function () {
        return matchApplicative;
    },
    Plus1: function () {
        return matchPlus;
    }
};
var lit = function (input) {
    return function (route) {
        if (route instanceof Data_List_Types.Cons && (route.value0 instanceof Routing_Types.Path && route.value0.value0 === input)) {
            return pure(new Data_Tuple.Tuple(route.value1, Data_Unit.unit));
        };
        if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path) {
            return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(new Routing_Match_Error.UnexpectedPath(input)));
        };
        return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedPathPart.value));
    };
};
var root = /* #__PURE__ */ lit("");
var list = function (v) {
    var go = function (accum) {
        return function (r) {
            return Data_Validation_Semiring.validation(Data_Function["const"](pure(new Data_Tuple.Tuple(r, Data_List.reverse(accum)))))(function (v1) {
                return go(new Data_List_Types.Cons(v1.value1, accum))(v1.value0);
            })(v(r));
        };
    };
    return go(Data_List_Types.Nil.value);
};
var $$int = function (route) {
    if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path) {
        var v = Data_Int.fromString(route.value0.value0);
        if (v instanceof Data_Maybe.Nothing) {
            return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedInt.value));
        };
        if (v instanceof Data_Maybe.Just) {
            return pure(new Data_Tuple.Tuple(route.value1, v.value0));
        };
        throw new Error("Failed pattern match at Routing.Match (line 85, column 29 - line 87, column 38): " + [ v.constructor.name ]);
    };
    return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedInt.value));
};
var fail = function (msg) {
    return function (v) {
        return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(new Routing_Match_Error.Fail(msg)));
    };
};
var end = function (route) {
    if (route instanceof Data_List_Types.Nil) {
        return pure(new Data_Tuple.Tuple(Data_List_Types.Nil.value, Data_Unit.unit));
    };
    return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedEnd.value));
};
var eitherMatch = function (v) {
    var runEither = function (v1) {
        if (v1.value1 instanceof Data_Either.Left) {
            return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(new Routing_Match_Error.Fail("Nested check failed")));
        };
        if (v1.value1 instanceof Data_Either.Right) {
            return pure(new Data_Tuple.Tuple(v1.value0, v1.value1.value0));
        };
        throw new Error("Failed pattern match at Routing.Match (line 202, column 5 - line 204, column 39): " + [ v1.value1.constructor.name ]);
    };
    return function (r) {
        return Data_Validation_Semiring.validation(Data_Validation_Semiring.invalid)(runEither)(v(r));
    };
};
var nonempty = /* #__PURE__ */ eitherMatch(/* #__PURE__ */ Data_Functor.map(matchFunctor)(/* #__PURE__ */ (function () {
    var $112 = Data_Maybe.maybe(new Data_Either.Left("Empty string"))(Data_Either.Right.create);
    return function ($113) {
        return $112(Data_String_NonEmpty_Internal.fromString($113));
    };
})())(str));
var bool = function (route) {
    if (route instanceof Data_List_Types.Cons && (route.value0 instanceof Routing_Types.Path && route.value0.value0 === "true")) {
        return pure(new Data_Tuple.Tuple(route.value1, true));
    };
    if (route instanceof Data_List_Types.Cons && (route.value0 instanceof Routing_Types.Path && route.value0.value0 === "false")) {
        return pure(new Data_Tuple.Tuple(route.value1, false));
    };
    return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedBoolean.value));
};
export {
    Match,
    root,
    lit,
    num,
    $$int as int,
    bool,
    str,
    param,
    params,
    end,
    fail,
    nonempty,
    list,
    runMatch,
    eitherMatch,
    optionalMatch,
    newtypeMatch,
    matchFunctor,
    matchAlt,
    matchPlus,
    matchAlternative,
    matchApply,
    matchApplicative
};
