// Generated by purs version 0.15.15
import * as Color from "../Color/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
var mod = /* #__PURE__ */ Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt);
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var span = /* #__PURE__ */ Concur_React_DOM.span(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var div1 = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var tomwellsOrg = "  __                               .__  .__                                  \x0a_/  |_  ____   _______  _  __ ____ |  | |  |   ______     ___________  ____  \x0a\\   __\\/  _ \\ /     \\ \\/ \\/ // __ \\|  | |  |  /  ___/    /  _ \\_  __ \\/ ___\\ \x0a |  | (  <_> )  Y Y  \\     /\\  ___/|  |_|  |__\\___ \\    (  <_> )  | \\/ /_/  >\x0a |__|  \\____/|__|_|  /\\/\\_/  \\___  >____/____/____  > /\\ \\____/|__|  \\___  / \x0a                   \\/            \\/               \\/  \\/            /_____/  ";
var stream = "          __                                 \x0a  _______/  |________   ____ _____    _____  \x0a /  ___/\\   __\\_  __ \\_/ __ \\\\__  \\  /     \\ \x0a \\___ \\  |  |  |  | \\/\\  ___/ / __ \\|  Y Y  \\\x0a/____  > |__|  |__|    \\___  >____  /__|_|  /\x0a     \\/                    \\/     \\/      \\/ ";
var rainbow = function (input) {
    var rows = Data_Array.length(Data_String_Common.split("\x0a")(input));
    var ratio = function (t_min) {
        return function (t_max) {
            return function (v_min) {
                return function (v_max) {
                    return function (v) {
                        return Data_Int.round((Data_Int.toNumber(v) / Data_Int.toNumber((v_max - v_min | 0) + 1 | 0)) * Data_Int.toNumber((t_max - t_min | 0) + 1 | 0));
                    };
                };
            };
        };
    };
    var cols = Data_String_CodePoints.length(Data_Maybe.fromMaybe("")(Data_Array.head(Data_String_Common.split("\x0a")(input)))) + 1 | 0;
    var colour = function (index) {
        return Color.toHexString(Color.rgb(ratio(0)(255)(0)(cols - 1 | 0)(mod(index)(cols)))(20)(140));
    };
    var point = function (v) {
        return span([ Concur_React_Props.style({
            color: colour(v.value0)
        }) ])([ text(Data_String_CodeUnits.singleton(v.value1)) ]);
    };
    return div1([  ])(map(point)(Data_Array.zip(Data_Array.range(0)(Data_String_CodePoints.length(input)))(Data_String_CodeUnits.toCharArray(input))));
};
var notFound = "                         _____  _______      _____  \x0a  __________________    /  |  | \\   _  \\    /  |  | \x0a_/ __ \\_  __ \\_  __ \\  /   |  |_/  /_\\  \\  /   |  |_\x0a\\  ___/|  | \\/|  | \\/ /    ^   /\\  \\_/   \\/    ^   /\x0a \\___  >__|   |__|    \\____   |  \\_____  /\\____   | \x0a     \\/                    |__|        \\/      |__| ";
var nbsp = "\xa0";
var guestbook = "                                __ ___.                  __    \x0a   ____  __ __   ____   _______/  |\\_ |__   ____   ____ |  | __\x0a  / ___\\|  |  \\_/ __ \\ /  ___/\\   __\\ __ \\ /  _ \\ /  _ \\|  |/ /\x0a / /_/  >  |  /\\  ___/ \\___ \\  |  | | \\_\\ (  <_> |  <_> )    < \x0a \\___  /|____/  \\___  >____  > |__| |___  /\\____/ \\____/|__|_ \\\x0a/_____/             \\/     \\/           \\/                   \\/";
var cv = "      ___.                  __                    \x0a_____ \\_ |__   ____  __ ___/  |_    _____   ____  \x0a\\__  \\ | __ \\ /  _ \\|  |  \\   __\\  /     \\_/ __ \\ \x0a / __ \\| \\_\\ (  <_> )  |  /|  |   |  Y Y  \\  ___/ \x0a(____  /___  /\\____/|____/ |__|   |__|_|  /\\___  >\x0a     \\/    \\/                           \\/     \\/ ";
var contact = ".__    .__  __                                 ._.\x0a|  |__ |__|/  |_    _____   ____    __ ________| |\x0a|  |  \\|  \\   __\\  /     \\_/ __ \\  |  |  \\____ \\ |\x0a|   Y  \\  ||  |   |  Y Y  \\  ___/  |  |  /  |_> >|\x0a|___|  /__||__|   |__|_|  /\\___  > |____/|   __/__\x0a     \\/                 \\/     \\/        |__|   \\/";
var blog = "___.   .__                 \x0a\\_ |__ |  |   ____   ____  \x0a | __ \\|  |  /  _ \\ / ___\\ \x0a | \\_\\ \\  |_(  <_> ) /_/  >\x0a |___  /____/\\____/\\___  / \x0a     \\/           /_____/  ";
export {
    nbsp,
    rainbow,
    tomwellsOrg,
    stream,
    guestbook,
    blog,
    cv,
    contact,
    notFound
};
