// Generated by purs version 0.15.15
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Effect from "../Effect/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Duplex_Generic from "../Routing.Duplex.Generic/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Location from "../Web.HTML.Location/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var bind = /* #__PURE__ */ Control_Bind.bind(Effect.bindEffect);
var BlogSummary = /* #__PURE__ */ (function () {
    function BlogSummary() {

    };
    BlogSummary.value = new BlogSummary();
    return BlogSummary;
})();
var BlogArticle = /* #__PURE__ */ (function () {
    function BlogArticle(value0) {
        this.value0 = value0;
    };
    BlogArticle.create = function (value0) {
        return new BlogArticle(value0);
    };
    return BlogArticle;
})();
var Stream = /* #__PURE__ */ (function () {
    function Stream() {

    };
    Stream.value = new Stream();
    return Stream;
})();
var Resume = /* #__PURE__ */ (function () {
    function Resume() {

    };
    Resume.value = new Resume();
    return Resume;
})();
var Contact = /* #__PURE__ */ (function () {
    function Contact() {

    };
    Contact.value = new Contact();
    return Contact;
})();
var Guestbook = /* #__PURE__ */ (function () {
    function Guestbook() {

    };
    Guestbook.value = new Guestbook();
    return Guestbook;
})();
var NotFound = /* #__PURE__ */ (function () {
    function NotFound() {

    };
    NotFound.value = new NotFound();
    return NotFound;
})();
var genericRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return BlogSummary.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new BlogArticle(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return Stream.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return Resume.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return Contact.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return Guestbook.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))) {
            return NotFound.value;
        };
        throw new Error("Failed pattern match at TomWellsOrg.Nav (line 23, column 1 - line 23, column 47): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof BlogSummary) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof BlogArticle) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof Stream) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof Resume) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof Contact) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof Guestbook) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
        };
        if (x instanceof NotFound) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))))));
        };
        throw new Error("Failed pattern match at TomWellsOrg.Nav (line 23, column 1 - line 23, column 47): " + [ x.constructor.name ]);
    }
};
var routes = /* #__PURE__ */ Routing_Duplex.root(/* #__PURE__ */ Routing_Duplex_Generic.sum(genericRoute)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "BlogSummary";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "BlogArticle";
    }
})()(Routing_Duplex_Generic.gRouteAll))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Stream";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Resume";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Contact";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Guestbook";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "NotFound";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))))))))({
    BlogSummary: /* #__PURE__ */ Routing_Duplex.path("blog")(Routing_Duplex_Generic.noArgs),
    BlogArticle: /* #__PURE__ */ Routing_Duplex.path("blog")(/* #__PURE__ */ Routing_Duplex.string(Routing_Duplex.segment)),
    Stream: Routing_Duplex_Generic.noArgs,
    Resume: /* #__PURE__ */ Routing_Duplex.path("cv")(Routing_Duplex_Generic.noArgs),
    Contact: /* #__PURE__ */ Routing_Duplex.path("contact")(Routing_Duplex_Generic.noArgs),
    Guestbook: /* #__PURE__ */ Routing_Duplex.path("guestbook")(Routing_Duplex_Generic.noArgs),
    NotFound: /* #__PURE__ */ Routing_Duplex.path("notfound")(Routing_Duplex_Generic.noArgs)
}));
var parseRoute = function (pathname) {
    return Data_Either.either(function (v) {
        return NotFound.value;
    })(identity)(Routing_Duplex.parse(routes)(pathname));
};
var printRoute = /* #__PURE__ */ Routing_Duplex.print(routes);
var currentRoute = /* #__PURE__ */ Data_Functor.mapFlipped(Effect.functorEffect)(/* #__PURE__ */ bind(/* #__PURE__ */ bind(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.pathname))(parseRoute);
export {
    BlogSummary,
    BlogArticle,
    Stream,
    Resume,
    Contact,
    Guestbook,
    NotFound,
    routes,
    parseRoute,
    printRoute,
    currentRoute,
    genericRoute
};
