// Generated by purs version 0.15.15
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Eq_Generic from "../Data.Eq.Generic/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ord_Generic from "../Data.Ord.Generic/index.js";
import * as Data_Ratio from "../Data.Ratio/index.js";
import * as Data_Ring from "../Data.Ring/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_String_Pattern from "../Data.String.Pattern/index.js";
import * as Data_String_Regex from "../Data.String.Regex/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Control_Applicative.applicativeArray);
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var genericShowArgsArgument = /* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showInt);
var genericShowArgsProduct = /* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(genericShowArgsArgument);
var showRecord = /* #__PURE__ */ Data_Show.showRecord()();
var distanceIsSymbol = {
    reflectSymbol: function () {
        return "distance";
    }
};
var showRecordFieldsCons = /* #__PURE__ */ Data_Show.showRecordFieldsCons(distanceIsSymbol);
var originalIsSymbol = {
    reflectSymbol: function () {
        return "original";
    }
};
var showRecordFieldsCons1 = /* #__PURE__ */ Data_Show.showRecordFieldsCons(originalIsSymbol);
var ratioIsSymbol = {
    reflectSymbol: function () {
        return "ratio";
    }
};
var showRecordFieldsCons2 = /* #__PURE__ */ Data_Show.showRecordFieldsCons(ratioIsSymbol);
var segmentsIsSymbol = {
    reflectSymbol: function () {
        return "segments";
    }
};
var showRecordFieldsConsNil = /* #__PURE__ */ Data_Show.showRecordFieldsConsNil(segmentsIsSymbol);
var showArray = /* #__PURE__ */ Data_Show.showArray(/* #__PURE__ */ Data_Either.showEither(Data_Show.showString)(Data_Show.showString));
var showRatio = /* #__PURE__ */ Data_Ratio.showRatio(Data_Show.showInt);
var showRecordFieldsCons3 = /* #__PURE__ */ showRecordFieldsCons1(/* #__PURE__ */ showRecordFieldsCons2(/* #__PURE__ */ showRecordFieldsConsNil(/* #__PURE__ */ Foreign_Object.showObject(showArray)))(showRatio));
var FuzzyIsSymbol = {
    reflectSymbol: function () {
        return "Fuzzy";
    }
};
var genericEqConstructor = /* #__PURE__ */ Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments);
var genericEqSum = /* #__PURE__ */ Data_Eq_Generic.genericEqSum(genericEqConstructor);
var genericEqSum1 = /* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(genericEqConstructor));
var reduce = /* #__PURE__ */ Data_Ratio.reduce(Data_Ord.ordInt)(Data_EuclideanRing.euclideanRingInt);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(Data_Monoid.monoidArray);
var append = /* #__PURE__ */ Data_Semigroup.append(/* #__PURE__ */ Data_Either.semigroupEither(Data_Semigroup.semigroupString));
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var sub1 = /* #__PURE__ */ Data_Ring.sub(/* #__PURE__ */ Data_Ratio.ringRatio(Data_Ord.ordInt)(Data_EuclideanRing.euclideanRingInt));
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_Foldable.foldableArray);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var eqRatio = /* #__PURE__ */ Data_Ratio.eqRatio(Data_Eq.eqInt);
var eq = /* #__PURE__ */ Data_Eq.eq(eqRatio);
var genericEqArgument = /* #__PURE__ */ Data_Eq_Generic.genericEqArgument(Data_Eq.eqInt);
var genericEqProduct = /* #__PURE__ */ Data_Eq_Generic.genericEqProduct(genericEqArgument);
var eqRec = /* #__PURE__ */ Data_Eq.eqRec();
var eqRowCons = /* #__PURE__ */ Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(segmentsIsSymbol);
var eqArray = /* #__PURE__ */ Data_Eq.eqArray(/* #__PURE__ */ Data_Either.eqEither(Data_Eq.eqString)(Data_Eq.eqString));
var eqRowCons1 = /* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ eqRowCons(/* #__PURE__ */ Foreign_Object.eqObject(eqArray)))()(ratioIsSymbol)(eqRatio))()(originalIsSymbol);
var genericOrdArgument = /* #__PURE__ */ Data_Ord_Generic.genericOrdArgument(Data_Ord.ordInt);
var genericOrdProduct = /* #__PURE__ */ Data_Ord_Generic.genericOrdProduct(genericOrdArgument);
var map1 = /* #__PURE__ */ Data_Functor.map(Foreign_Object.functorObject);
var max = /* #__PURE__ */ Data_Ord.max(/* #__PURE__ */ Data_Ratio.ordRatio(Data_Ord.ordInt)(Data_EuclideanRing.euclideanRingInt));
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var Full = /* #__PURE__ */ (function () {
    function Full() {

    };
    Full.value = new Full();
    return Full;
})();
var Word = /* #__PURE__ */ (function () {
    function Word() {

    };
    Word.value = new Word();
    return Word;
})();
var Char = /* #__PURE__ */ (function () {
    function Char() {

    };
    Char.value = new Char();
    return Char;
})();
var Start = /* #__PURE__ */ (function () {
    function Start() {

    };
    Start.value = new Start();
    return Start;
})();
var Prefix = /* #__PURE__ */ (function () {
    function Prefix() {

    };
    Prefix.value = new Prefix();
    return Prefix;
})();
var Mid = /* #__PURE__ */ (function () {
    function Mid() {

    };
    Mid.value = new Mid();
    return Mid;
})();
var Suffix = /* #__PURE__ */ (function () {
    function Suffix() {

    };
    Suffix.value = new Suffix();
    return Suffix;
})();
var End = /* #__PURE__ */ (function () {
    function End() {

    };
    End.value = new End();
    return End;
})();
var Distance = /* #__PURE__ */ (function () {
    function Distance(value0, value1, value2, value3, value4, value5) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
        this.value4 = value4;
        this.value5 = value5;
    };
    Distance.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return function (value4) {
                        return function (value5) {
                            return new Distance(value0, value1, value2, value3, value4, value5);
                        };
                    };
                };
            };
        };
    };
    return Distance;
})();
var None = /* #__PURE__ */ (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var Fuzzy = function (x) {
    return x;
};
var FuzzyStr = function (x) {
    return x;
};
var words = /* #__PURE__ */ (function () {
    var regex$prime = Data_String_Regex.regex("\\s+")(Data_String_Regex.parseFlags("g"));
    return Data_Either.either(function ($353) {
        return Data_Function["const"](pure($353));
    })(Data_String_Regex.split)(regex$prime);
})();
var semigroupDistance = {
    append: function (v) {
        return function (v1) {
            if (v instanceof None) {
                return v1;
            };
            if (v1 instanceof None) {
                return v;
            };
            if (v instanceof Distance && v1 instanceof Distance) {
                return new Distance(v.value0 + v1.value0 | 0, v.value1 + v1.value1 | 0, v.value2 + v1.value2 | 0, v.value3 + v1.value3 | 0, v.value4 + v1.value4 | 0, v.value5 + v1.value5 | 0);
            };
            throw new Error("Failed pattern match at Data.Fuzzy (line 78, column 1 - line 82, column 67): " + [ v.constructor.name, v1.constructor.name ]);
        };
    }
};
var append2 = /* #__PURE__ */ Data_Semigroup.append(semigroupDistance);
var scoreScope = /* #__PURE__ */ (function () {
    return append2(new Distance(1, 0, 0, 0, 0, 0));
})();
var scoreDistance = function (v) {
    return function (v1) {
        if (v instanceof Start) {
            return new Distance(0, 0, 0, v1, 0, 0);
        };
        if (v instanceof Prefix) {
            return new Distance(0, 0, v1, 0, 0, 0);
        };
        if (v instanceof Mid) {
            return new Distance(0, v1, 0, 0, 0, 0);
        };
        if (v instanceof Suffix) {
            return new Distance(0, 0, 0, 0, v1, 0);
        };
        if (v instanceof End) {
            return new Distance(0, 0, 0, 0, 0, v1);
        };
        throw new Error("Failed pattern match at Data.Fuzzy (line 359, column 1 - line 359, column 40): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var newtypeFuzzyStr = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeFuzzy = {
    Coercible0: function () {
        return undefined;
    }
};
var monoidDistance = /* #__PURE__ */ (function () {
    return {
        mempty: None.value,
        Semigroup0: function () {
            return semigroupDistance;
        }
    };
})();
var mempty1 = /* #__PURE__ */ Data_Monoid.mempty(monoidDistance);
var lastIndexOf$prime = function ($354) {
    return Data_String_CodePoints.lastIndexOf(Data_String_Pattern.Pattern($354));
};
var indexOf$prime = function ($355) {
    return Data_String_CodePoints.indexOf(Data_String_Pattern.Pattern($355));
};
var scoreWord = function (pos) {
    return function (distance) {
        return function (str) {
            var before = Data_String_CodePoints.take(distance)(str);
            var wordStart = Data_String_CodePoints.length(before) - (Data_Maybe.fromMaybe(-1 | 0)(lastIndexOf$prime(" ")(before)) + 1 | 0) | 0;
            var after = Data_String_CodePoints.length(str);
            var wordEnd = Data_Maybe.fromMaybe(1 * after | 0)(indexOf$prime(" ")(str));
            if (pos instanceof Start) {
                return scoreDistance(Prefix.value)(wordStart);
            };
            if (pos instanceof End) {
                return scoreDistance(Suffix.value)(wordEnd);
            };
            return mempty1;
        };
    };
};
var genericScope = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Full.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Word.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return Char.value;
        };
        throw new Error("Failed pattern match at Data.Fuzzy (line 149, column 1 - line 149, column 48): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Full) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Word) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof Char) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
        };
        throw new Error("Failed pattern match at Data.Fuzzy (line 149, column 1 - line 149, column 48): " + [ x.constructor.name ]);
    }
};
var showScope = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericScope)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Full";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Word";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Char";
        }
    }))))
};
var genericPos = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Start.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Prefix.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return Mid.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return Suffix.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return End.value;
        };
        throw new Error("Failed pattern match at Data.Fuzzy (line 155, column 1 - line 155, column 44): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Start) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Prefix) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof Mid) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof Suffix) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof End) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
        };
        throw new Error("Failed pattern match at Data.Fuzzy (line 155, column 1 - line 155, column 44): " + [ x.constructor.name ]);
    }
};
var showPos = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericPos)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Start";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Prefix";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Mid";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Suffix";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "End";
        }
    }))))))
};
var genericFuzzyStr = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var genericFuzzy = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var genericShow = /* #__PURE__ */ Data_Show_Generic.genericShow(genericFuzzy);
var genericEq = /* #__PURE__ */ Data_Eq_Generic.genericEq(genericFuzzy);
var genericDistance = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Distance(x.value0.value0, x.value0.value1.value0, x.value0.value1.value1.value0, x.value0.value1.value1.value1.value0, x.value0.value1.value1.value1.value1.value0, x.value0.value1.value1.value1.value1.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return None.value;
        };
        throw new Error("Failed pattern match at Data.Fuzzy (line 73, column 1 - line 73, column 54): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Distance) {
            return new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, new Data_Generic_Rep.Product(x.value1, new Data_Generic_Rep.Product(x.value2, new Data_Generic_Rep.Product(x.value3, new Data_Generic_Rep.Product(x.value4, x.value5))))));
        };
        if (x instanceof None) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Data.Fuzzy (line 73, column 1 - line 73, column 54): " + [ x.constructor.name ]);
    }
};
var showDistance = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericDistance)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ genericShowArgsProduct(/* #__PURE__ */ genericShowArgsProduct(/* #__PURE__ */ genericShowArgsProduct(/* #__PURE__ */ genericShowArgsProduct(/* #__PURE__ */ genericShowArgsProduct(genericShowArgsArgument))))))({
        reflectSymbol: function () {
            return "Distance";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "None";
        }
    })))
};
var showFuzzy = function (dictShow) {
    return {
        show: genericShow(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(showRecord(showRecordFieldsCons(showRecordFieldsCons3(dictShow))(showDistance))))(FuzzyIsSymbol))
    };
};
var showFuzzyStr = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericFuzzyStr)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ showRecord(/* #__PURE__ */ showRecordFieldsCons(/* #__PURE__ */ showRecordFieldsCons1(/* #__PURE__ */ showRecordFieldsCons2(/* #__PURE__ */ showRecordFieldsConsNil(showArray))(showRatio))(Data_Show.showString))(showDistance))))({
        reflectSymbol: function () {
            return "FuzzyStr";
        }
    }))
};
var eqScope = {
    eq: /* #__PURE__ */ Data_Eq_Generic.genericEq(genericScope)(genericEqSum1)
};
var eq1 = /* #__PURE__ */ Data_Eq.eq(eqScope);
var matchStr = function (v) {
    return function (v1) {
        return function (v2) {
            if (v1 === "") {
                return {
                    original: v2,
                    segments: [ new Data_Either.Left(v2) ],
                    distance: None.value,
                    ratio: reduce(1)(1)
                };
            };
            var initialAcc = {
                substr: v2,
                pos: Start.value,
                fuzzy: {
                    original: v2,
                    segments: mempty,
                    distance: mempty1,
                    ratio: reduce(1)(1)
                }
            };
            var chars = Data_String_CodePoints.length(Data_String_Common.replaceAll(" ")("")(v1));
            var matchStr$prime = function (scope) {
                return function (v3) {
                    return function (pat) {
                        var v4 = (function () {
                            if (v) {
                                return new Data_Tuple.Tuple(Data_String_Common.toLower(pat), Data_String_Common.toLower(v3.substr));
                            };
                            return new Data_Tuple.Tuple(pat, v3.substr);
                        })();
                        var nextRight = function (d) {
                            return new Data_Either.Right(Data_String_CodePoints.take(Data_String_CodePoints.length(pat))(Data_String_CodePoints.drop(d)(v3.substr)));
                        };
                        var nextLeft = function (d) {
                            if (d === 0) {
                                return mempty;
                            };
                            return [ new Data_Either.Left(Data_String_CodePoints.take(d)(v3.substr)) ];
                        };
                        var nextSegment = function (d) {
                            var v5 = new Data_Tuple.Tuple(d, Data_Array.unsnoc(v3.fuzzy.segments));
                            if (v5.value0 === 0 && v5.value1 instanceof Data_Maybe.Just) {
                                return Data_Array.snoc(v5.value1.value0.init)(append(v5.value1.value0.last)(nextRight(d)));
                            };
                            return append1(v3.fuzzy.segments)(append1(nextLeft(d))([ nextRight(d) ]));
                        };
                        var nextDistance = function (d) {
                            var v5 = new Data_Tuple.Tuple(scope, v3.pos);
                            if (v5.value0 instanceof Word && v5.value1 instanceof Mid) {
                                return append2(v3.fuzzy.distance)(scoreDistance(Start.value)(d));
                            };
                            return append2(v3.fuzzy.distance)(append2(scoreDistance(v3.pos)(d))(scoreWord(v3.pos)(d)(v4.value1)));
                        };
                        var nextAcc = function (p) {
                            return {
                                substr: v3.substr,
                                pos: p,
                                fuzzy: {
                                    original: v3.fuzzy.original,
                                    segments: v3.fuzzy.segments,
                                    distance: scoreScope(v3.fuzzy.distance),
                                    ratio: (function () {
                                        var $321 = eq1(scope)(Char.value);
                                        if ($321) {
                                            return sub1(v3.fuzzy.ratio)(reduce(1)(chars));
                                        };
                                        return v3.fuzzy.ratio;
                                    })()
                                }
                            };
                        };
                        var v5 = indexOf$prime(v4.value0)(v4.value1);
                        if (v5 instanceof Data_Maybe.Just) {
                            return {
                                substr: Data_String_CodePoints.drop(v5.value0 + Data_String_CodePoints.length(pat) | 0)(v3.substr),
                                pos: Mid.value,
                                fuzzy: {
                                    original: v3.fuzzy.original,
                                    segments: nextSegment(v5.value0),
                                    distance: nextDistance(v5.value0),
                                    ratio: v3.fuzzy.ratio
                                }
                            };
                        };
                        if (v5 instanceof Data_Maybe.Nothing) {
                            if (scope instanceof Full) {
                                return foldl(matchStr$prime(Word.value))(nextAcc(Start.value))(words(pat));
                            };
                            if (scope instanceof Word) {
                                return foldl(matchStr$prime(Char.value))(nextAcc(Start.value))(map(Data_String_CodePoints.singleton)(Data_String_CodePoints.toCodePointArray(pat)));
                            };
                            if (scope instanceof Char) {
                                return nextAcc(v3.pos);
                            };
                            throw new Error("Failed pattern match at Data.Fuzzy (line 238, column 11 - line 241, column 32): " + [ scope.constructor.name ]);
                        };
                        throw new Error("Failed pattern match at Data.Fuzzy (line 226, column 7 - line 241, column 32): " + [ v5.constructor.name ]);
                    };
                };
            };
            var after = function (v3) {
                var nextSegment = (function () {
                    if (v3.substr === "") {
                        return v3.fuzzy.segments;
                    };
                    return Data_Array.snoc(v3.fuzzy.segments)(new Data_Either.Left(v3.substr));
                })();
                var nextDistance = (function () {
                    if (eq(v3.fuzzy.ratio)(reduce(0)(1))) {
                        return v3.fuzzy.distance;
                    };
                    if (Data_Boolean.otherwise) {
                        return append2(v3.fuzzy.distance)(append2(scoreDistance(End.value)(Data_String_CodePoints.length(v3.substr)))(scoreWord(End.value)(0)(v3.substr)));
                    };
                    throw new Error("Failed pattern match at Data.Fuzzy (line 287, column 11 - line 287, column 35): " + [  ]);
                })();
                return {
                    original: v3.fuzzy.original,
                    segments: nextSegment,
                    distance: nextDistance,
                    ratio: v3.fuzzy.ratio
                };
            };
            return after(foldl(matchStr$prime(Full.value))(initialAcc)([ v1 ]));
        };
    };
};
var eqPos = {
    eq: /* #__PURE__ */ Data_Eq_Generic.genericEq(genericPos)(/* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(genericEqSum1)))
};
var eqDistance = {
    eq: /* #__PURE__ */ Data_Eq_Generic.genericEq(genericDistance)(/* #__PURE__ */ Data_Eq_Generic.genericEqSum(/* #__PURE__ */ Data_Eq_Generic.genericEqConstructor(/* #__PURE__ */ genericEqProduct(/* #__PURE__ */ genericEqProduct(/* #__PURE__ */ genericEqProduct(/* #__PURE__ */ genericEqProduct(/* #__PURE__ */ genericEqProduct(genericEqArgument)))))))(genericEqConstructor))
};
var eqFuzzy = function (dictEq) {
    return {
        eq: genericEq(Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqArgument(eqRec(Data_Eq.eqRowCons(eqRowCons1(dictEq))()(distanceIsSymbol)(eqDistance)))))
    };
};
var eqFuzzyStr = {
    eq: /* #__PURE__ */ Data_Eq_Generic.genericEq(genericFuzzyStr)(/* #__PURE__ */ Data_Eq_Generic.genericEqConstructor(/* #__PURE__ */ Data_Eq_Generic.genericEqArgument(/* #__PURE__ */ eqRec(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ eqRowCons(eqArray))()(ratioIsSymbol)(eqRatio))()(originalIsSymbol)(Data_Eq.eqString))()(distanceIsSymbol)(eqDistance)))))
};
var ordDistance = {
    compare: /* #__PURE__ */ Data_Ord_Generic.genericCompare(genericDistance)(/* #__PURE__ */ Data_Ord_Generic.genericOrdSum(/* #__PURE__ */ Data_Ord_Generic.genericOrdConstructor(/* #__PURE__ */ genericOrdProduct(/* #__PURE__ */ genericOrdProduct(/* #__PURE__ */ genericOrdProduct(/* #__PURE__ */ genericOrdProduct(/* #__PURE__ */ genericOrdProduct(genericOrdArgument)))))))(/* #__PURE__ */ Data_Ord_Generic.genericOrdConstructor(Data_Ord_Generic.genericOrdNoArguments))),
    Eq0: function () {
        return eqDistance;
    }
};
var min = /* #__PURE__ */ Data_Ord.min(ordDistance);
var compare = /* #__PURE__ */ Data_Ord.compare(ordDistance);
var match = function (v) {
    return function (v1) {
        return function (v2) {
            return function (v3) {
                if (v2 === "") {
                    return {
                        original: v3,
                        segments: map1(function ($356) {
                            return pure(Data_Either.Left.create($356));
                        })(v1(v3)),
                        distance: mempty1,
                        ratio: reduce(1)(1)
                    };
                };
                var minDistance = function (d) {
                    return function (v4) {
                        return min(d)(v4.distance);
                    };
                };
                var maxRatio = function (r) {
                    return function (v4) {
                        return max(r)(v4.ratio);
                    };
                };
                var matches = map1(matchStr(v)(v2))(v1(v3));
                var fuzzies = Foreign_Object.values(matches);
                return {
                    original: v3,
                    segments: map1(function ($357) {
                        return (function (v4) {
                            return v4.segments;
                        })(unwrap($357));
                    })(matches),
                    distance: foldl(minDistance)(mempty1)(fuzzies),
                    ratio: foldl(maxRatio)(reduce(0)(1))(fuzzies)
                };
            };
        };
    };
};
var ordFuzzy = function (dictEq) {
    var eqFuzzy1 = eqFuzzy(dictEq);
    return {
        compare: Data_Function.on(compare)(function ($358) {
            return (function (v) {
                return v.distance;
            })(unwrap($358));
        }),
        Eq0: function () {
            return eqFuzzy1;
        }
    };
};
var ordFuzzyStr = {
    compare: /* #__PURE__ */ Data_Function.on(compare)(function ($359) {
        return (function (v) {
            return v.distance;
        })(unwrap($359));
    }),
    Eq0: function () {
        return eqFuzzyStr;
    }
};
export {
    Distance,
    None,
    FuzzyStr,
    Fuzzy,
    matchStr,
    match,
    genericDistance,
    eqDistance,
    showDistance,
    ordDistance,
    semigroupDistance,
    monoidDistance,
    genericFuzzyStr,
    newtypeFuzzyStr,
    eqFuzzyStr,
    showFuzzyStr,
    ordFuzzyStr,
    genericFuzzy,
    newtypeFuzzy,
    eqFuzzy,
    showFuzzy,
    ordFuzzy
};
