// Generated by purs version 0.15.15
import * as React from "../React/index.js";
import * as React_DOM_Props from "../React.DOM.Props/index.js";
import * as Unsafe_Coerce from "../Unsafe.Coerce/index.js";
var IsDynamic = function (x) {
    return x;
};
var text = Unsafe_Coerce.unsafeCoerce;
var number = Unsafe_Coerce.unsafeCoerce;
var mkDOM = function (dynamic) {
    return function (tag) {
        return function (props) {
            var createElement = (function () {
                if (!dynamic) {
                    return React.createElementTagName;
                };
                if (dynamic) {
                    return React.createElementTagNameDynamic;
                };
                throw new Error("Failed pattern match at React.DOM (line 14, column 5 - line 16, column 52): " + [ dynamic.constructor.name ]);
            })();
            return createElement(tag)(React_DOM_Props.unsafeFromPropsArray(props));
        };
    };
};
var nav = /* #__PURE__ */ mkDOM(false)("nav");
var nav$prime = /* #__PURE__ */ nav([  ]);
var noscript = /* #__PURE__ */ mkDOM(false)("noscript");
var noscript$prime = /* #__PURE__ */ noscript([  ]);
var object = /* #__PURE__ */ mkDOM(false)("object");
var object$prime = /* #__PURE__ */ object([  ]);
var ol = /* #__PURE__ */ mkDOM(false)("ol");
var ol$prime = /* #__PURE__ */ ol([  ]);
var optgroup = /* #__PURE__ */ mkDOM(false)("optgroup");
var optgroup$prime = /* #__PURE__ */ optgroup([  ]);
var option = /* #__PURE__ */ mkDOM(false)("option");
var option$prime = /* #__PURE__ */ option([  ]);
var output = /* #__PURE__ */ mkDOM(false)("output");
var output$prime = /* #__PURE__ */ output([  ]);
var p = /* #__PURE__ */ mkDOM(false)("p");
var p$prime = /* #__PURE__ */ p([  ]);
var param = function (props) {
    return mkDOM(false)("param")(props)([  ]);
};
var param$prime = /* #__PURE__ */ param([  ]);
var picture = /* #__PURE__ */ mkDOM(false)("picture");
var picture$prime = /* #__PURE__ */ picture([  ]);
var pre = /* #__PURE__ */ mkDOM(false)("pre");
var pre$prime = /* #__PURE__ */ pre([  ]);
var progress = /* #__PURE__ */ mkDOM(false)("progress");
var progress$prime = /* #__PURE__ */ progress([  ]);
var q = /* #__PURE__ */ mkDOM(false)("q");
var q$prime = /* #__PURE__ */ q([  ]);
var rp = /* #__PURE__ */ mkDOM(false)("rp");
var rp$prime = /* #__PURE__ */ rp([  ]);
var rt = /* #__PURE__ */ mkDOM(false)("rt");
var rt$prime = /* #__PURE__ */ rt([  ]);
var ruby = /* #__PURE__ */ mkDOM(false)("ruby");
var ruby$prime = /* #__PURE__ */ ruby([  ]);
var s = /* #__PURE__ */ mkDOM(false)("s");
var s$prime = /* #__PURE__ */ s([  ]);
var samp = /* #__PURE__ */ mkDOM(false)("samp");
var samp$prime = /* #__PURE__ */ samp([  ]);
var script = /* #__PURE__ */ mkDOM(false)("script");
var script$prime = /* #__PURE__ */ script([  ]);
var section = /* #__PURE__ */ mkDOM(false)("section");
var section$prime = /* #__PURE__ */ section([  ]);
var select = /* #__PURE__ */ mkDOM(false)("select");
var select$prime = /* #__PURE__ */ select([  ]);
var small = /* #__PURE__ */ mkDOM(false)("small");
var small$prime = /* #__PURE__ */ small([  ]);
var source = function (props) {
    return mkDOM(false)("source")(props)([  ]);
};
var source$prime = /* #__PURE__ */ source([  ]);
var span = /* #__PURE__ */ mkDOM(false)("span");
var span$prime = /* #__PURE__ */ span([  ]);
var strong = /* #__PURE__ */ mkDOM(false)("strong");
var strong$prime = /* #__PURE__ */ strong([  ]);
var style = /* #__PURE__ */ mkDOM(false)("style");
var style$prime = /* #__PURE__ */ style([  ]);
var sub = /* #__PURE__ */ mkDOM(false)("sub");
var sub$prime = /* #__PURE__ */ sub([  ]);
var summary = /* #__PURE__ */ mkDOM(false)("summary");
var summary$prime = /* #__PURE__ */ summary([  ]);
var sup = /* #__PURE__ */ mkDOM(false)("sup");
var sup$prime = /* #__PURE__ */ sup([  ]);
var table = /* #__PURE__ */ mkDOM(false)("table");
var table$prime = /* #__PURE__ */ table([  ]);
var tbody = /* #__PURE__ */ mkDOM(false)("tbody");
var tbody$prime = /* #__PURE__ */ tbody([  ]);
var td = /* #__PURE__ */ mkDOM(false)("td");
var td$prime = /* #__PURE__ */ td([  ]);
var textarea = /* #__PURE__ */ mkDOM(false)("textarea");
var textarea$prime = /* #__PURE__ */ textarea([  ]);
var tfoot = /* #__PURE__ */ mkDOM(false)("tfoot");
var tfoot$prime = /* #__PURE__ */ tfoot([  ]);
var th = /* #__PURE__ */ mkDOM(false)("th");
var th$prime = /* #__PURE__ */ th([  ]);
var thead = /* #__PURE__ */ mkDOM(false)("thead");
var thead$prime = /* #__PURE__ */ thead([  ]);
var time = /* #__PURE__ */ mkDOM(false)("time");
var time$prime = /* #__PURE__ */ time([  ]);
var title = /* #__PURE__ */ mkDOM(false)("title");
var title$prime = /* #__PURE__ */ title([  ]);
var tr = /* #__PURE__ */ mkDOM(false)("tr");
var tr$prime = /* #__PURE__ */ tr([  ]);
var track = function (props) {
    return mkDOM(false)("track")(props)([  ]);
};
var track$prime = /* #__PURE__ */ track([  ]);
var u = /* #__PURE__ */ mkDOM(false)("u");
var u$prime = /* #__PURE__ */ u([  ]);
var ul = /* #__PURE__ */ mkDOM(false)("ul");
var ul$prime = /* #__PURE__ */ ul([  ]);
var $$var = /* #__PURE__ */ mkDOM(false)("var");
var var$prime = /* #__PURE__ */ $$var([  ]);
var video = /* #__PURE__ */ mkDOM(false)("video");
var video$prime = /* #__PURE__ */ video([  ]);
var wbr = function (props) {
    return mkDOM(false)("wbr")(props)([  ]);
};
var wbr$prime = /* #__PURE__ */ wbr([  ]);
var meter = /* #__PURE__ */ mkDOM(false)("meter");
var meter$prime = /* #__PURE__ */ meter([  ]);
var meta = function (props) {
    return mkDOM(false)("meta")(props)([  ]);
};
var meta$prime = /* #__PURE__ */ meta([  ]);
var menuitem = function (props) {
    return mkDOM(false)("menuitem")(props)([  ]);
};
var menuitem$prime = /* #__PURE__ */ menuitem([  ]);
var menu = /* #__PURE__ */ mkDOM(false)("menu");
var menu$prime = /* #__PURE__ */ menu([  ]);
var mark = /* #__PURE__ */ mkDOM(false)("mark");
var mark$prime = /* #__PURE__ */ mark([  ]);
var map = /* #__PURE__ */ mkDOM(false)("map");
var map$prime = /* #__PURE__ */ map([  ]);
var main = /* #__PURE__ */ mkDOM(false)("main");
var main$prime = /* #__PURE__ */ main([  ]);
var link = function (props) {
    return mkDOM(false)("link")(props)([  ]);
};
var link$prime = /* #__PURE__ */ link([  ]);
var li = /* #__PURE__ */ mkDOM(false)("li");
var li$prime = /* #__PURE__ */ li([  ]);
var legend = /* #__PURE__ */ mkDOM(false)("legend");
var legend$prime = /* #__PURE__ */ legend([  ]);
var label = /* #__PURE__ */ mkDOM(false)("label");
var label$prime = /* #__PURE__ */ label([  ]);
var keygen = function (props) {
    return mkDOM(false)("keygen")(props)([  ]);
};
var keygen$prime = /* #__PURE__ */ keygen([  ]);
var kbd = /* #__PURE__ */ mkDOM(false)("kbd");
var kbd$prime = /* #__PURE__ */ kbd([  ]);
var $$int = Unsafe_Coerce.unsafeCoerce;
var ins = /* #__PURE__ */ mkDOM(false)("ins");
var ins$prime = /* #__PURE__ */ ins([  ]);
var input = function (props) {
    return mkDOM(false)("input")(props)([  ]);
};
var input$prime = /* #__PURE__ */ input([  ]);
var img = function (props) {
    return mkDOM(false)("img")(props)([  ]);
};
var img$prime = /* #__PURE__ */ img([  ]);
var iframe = /* #__PURE__ */ mkDOM(false)("iframe");
var iframe$prime = /* #__PURE__ */ iframe([  ]);
var i = /* #__PURE__ */ mkDOM(false)("i");
var i$prime = /* #__PURE__ */ i([  ]);
var html = /* #__PURE__ */ mkDOM(false)("html");
var html$prime = /* #__PURE__ */ html([  ]);
var hr = function (props) {
    return mkDOM(false)("hr")(props)([  ]);
};
var hr$prime = /* #__PURE__ */ hr([  ]);
var header = /* #__PURE__ */ mkDOM(false)("header");
var header$prime = /* #__PURE__ */ header([  ]);
var head = /* #__PURE__ */ mkDOM(false)("head");
var head$prime = /* #__PURE__ */ head([  ]);
var h6 = /* #__PURE__ */ mkDOM(false)("h6");
var h6$prime = /* #__PURE__ */ h6([  ]);
var h5 = /* #__PURE__ */ mkDOM(false)("h5");
var h5$prime = /* #__PURE__ */ h5([  ]);
var h4 = /* #__PURE__ */ mkDOM(false)("h4");
var h4$prime = /* #__PURE__ */ h4([  ]);
var h3 = /* #__PURE__ */ mkDOM(false)("h3");
var h3$prime = /* #__PURE__ */ h3([  ]);
var h2 = /* #__PURE__ */ mkDOM(false)("h2");
var h2$prime = /* #__PURE__ */ h2([  ]);
var h1 = /* #__PURE__ */ mkDOM(false)("h1");
var h1$prime = /* #__PURE__ */ h1([  ]);
var form = /* #__PURE__ */ mkDOM(false)("form");
var form$prime = /* #__PURE__ */ form([  ]);
var footer = /* #__PURE__ */ mkDOM(false)("footer");
var footer$prime = /* #__PURE__ */ footer([  ]);
var figure = /* #__PURE__ */ mkDOM(false)("figure");
var figure$prime = /* #__PURE__ */ figure([  ]);
var figcaption = /* #__PURE__ */ mkDOM(false)("figcaption");
var figcaption$prime = /* #__PURE__ */ figcaption([  ]);
var fieldset = /* #__PURE__ */ mkDOM(false)("fieldset");
var fieldset$prime = /* #__PURE__ */ fieldset([  ]);
var embed = function (props) {
    return mkDOM(false)("embed")(props)([  ]);
};
var embed$prime = /* #__PURE__ */ embed([  ]);
var em = /* #__PURE__ */ mkDOM(false)("em");
var em$prime = /* #__PURE__ */ em([  ]);
var dt = /* #__PURE__ */ mkDOM(false)("dt");
var dt$prime = /* #__PURE__ */ dt([  ]);
var dl = /* #__PURE__ */ mkDOM(false)("dl");
var dl$prime = /* #__PURE__ */ dl([  ]);
var div = /* #__PURE__ */ mkDOM(false)("div");
var div$prime = /* #__PURE__ */ div([  ]);
var dialog = /* #__PURE__ */ mkDOM(false)("dialog");
var dialog$prime = /* #__PURE__ */ dialog([  ]);
var dfn = /* #__PURE__ */ mkDOM(false)("dfn");
var dfn$prime = /* #__PURE__ */ dfn([  ]);
var details = /* #__PURE__ */ mkDOM(false)("details");
var details$prime = /* #__PURE__ */ details([  ]);
var del = /* #__PURE__ */ mkDOM(false)("del");
var del$prime = /* #__PURE__ */ del([  ]);
var dd = /* #__PURE__ */ mkDOM(false)("dd");
var dd$prime = /* #__PURE__ */ dd([  ]);
var datalist = /* #__PURE__ */ mkDOM(false)("datalist");
var datalist$prime = /* #__PURE__ */ datalist([  ]);
var colgroup = /* #__PURE__ */ mkDOM(false)("colgroup");
var colgroup$prime = /* #__PURE__ */ colgroup([  ]);
var col = function (props) {
    return mkDOM(false)("col")(props)([  ]);
};
var col$prime = /* #__PURE__ */ col([  ]);
var code = /* #__PURE__ */ mkDOM(false)("code");
var code$prime = /* #__PURE__ */ code([  ]);
var cite = /* #__PURE__ */ mkDOM(false)("cite");
var cite$prime = /* #__PURE__ */ cite([  ]);
var caption = /* #__PURE__ */ mkDOM(false)("caption");
var caption$prime = /* #__PURE__ */ caption([  ]);
var canvas = /* #__PURE__ */ mkDOM(false)("canvas");
var canvas$prime = /* #__PURE__ */ canvas([  ]);
var button = /* #__PURE__ */ mkDOM(false)("button");
var button$prime = /* #__PURE__ */ button([  ]);
var br = function (props) {
    return mkDOM(false)("br")(props)([  ]);
};
var br$prime = /* #__PURE__ */ br([  ]);
var body = /* #__PURE__ */ mkDOM(false)("body");
var body$prime = /* #__PURE__ */ body([  ]);
var blockquote = /* #__PURE__ */ mkDOM(false)("blockquote");
var blockquote$prime = /* #__PURE__ */ blockquote([  ]);
var big = /* #__PURE__ */ mkDOM(false)("big");
var big$prime = /* #__PURE__ */ big([  ]);
var bdo = /* #__PURE__ */ mkDOM(false)("bdo");
var bdo$prime = /* #__PURE__ */ bdo([  ]);
var bdi = /* #__PURE__ */ mkDOM(false)("bdi");
var bdi$prime = /* #__PURE__ */ bdi([  ]);
var base = function (props) {
    return mkDOM(false)("base")(props)([  ]);
};
var base$prime = /* #__PURE__ */ base([  ]);
var b = /* #__PURE__ */ mkDOM(false)("b");
var b$prime = /* #__PURE__ */ b([  ]);
var audio = /* #__PURE__ */ mkDOM(false)("audio");
var audio$prime = /* #__PURE__ */ audio([  ]);
var aside = /* #__PURE__ */ mkDOM(false)("aside");
var aside$prime = /* #__PURE__ */ aside([  ]);
var article = /* #__PURE__ */ mkDOM(false)("article");
var article$prime = /* #__PURE__ */ article([  ]);
var area = function (props) {
    return mkDOM(false)("area")(props)([  ]);
};
var area$prime = /* #__PURE__ */ area([  ]);
var address = /* #__PURE__ */ mkDOM(false)("address");
var address$prime = /* #__PURE__ */ address([  ]);
var abbr = /* #__PURE__ */ mkDOM(false)("abbr");
var abbr$prime = /* #__PURE__ */ abbr([  ]);
var a = /* #__PURE__ */ mkDOM(false)("a");
var a$prime = /* #__PURE__ */ a([  ]);
var _data = /* #__PURE__ */ mkDOM(false)("data");
var _data$prime = /* #__PURE__ */ _data([  ]);
export {
    IsDynamic,
    mkDOM,
    text,
    $$int as int,
    number,
    a,
    a$prime,
    abbr,
    abbr$prime,
    address,
    address$prime,
    area,
    area$prime,
    article,
    article$prime,
    aside,
    aside$prime,
    audio,
    audio$prime,
    b,
    b$prime,
    base,
    base$prime,
    bdi,
    bdi$prime,
    bdo,
    bdo$prime,
    big,
    big$prime,
    blockquote,
    blockquote$prime,
    body,
    body$prime,
    br,
    br$prime,
    button,
    button$prime,
    canvas,
    canvas$prime,
    caption,
    caption$prime,
    cite,
    cite$prime,
    code,
    code$prime,
    col,
    col$prime,
    colgroup,
    colgroup$prime,
    _data,
    _data$prime,
    datalist,
    datalist$prime,
    dd,
    dd$prime,
    del,
    del$prime,
    details,
    details$prime,
    dfn,
    dfn$prime,
    dialog,
    dialog$prime,
    div,
    div$prime,
    dl,
    dl$prime,
    dt,
    dt$prime,
    em,
    em$prime,
    embed,
    embed$prime,
    fieldset,
    fieldset$prime,
    figcaption,
    figcaption$prime,
    figure,
    figure$prime,
    footer,
    footer$prime,
    form,
    form$prime,
    h1,
    h1$prime,
    h2,
    h2$prime,
    h3,
    h3$prime,
    h4,
    h4$prime,
    h5,
    h5$prime,
    h6,
    h6$prime,
    head,
    head$prime,
    header,
    header$prime,
    hr,
    hr$prime,
    html,
    html$prime,
    i,
    i$prime,
    iframe,
    iframe$prime,
    img,
    img$prime,
    input,
    input$prime,
    ins,
    ins$prime,
    kbd,
    kbd$prime,
    keygen,
    keygen$prime,
    label,
    label$prime,
    legend,
    legend$prime,
    li,
    li$prime,
    link,
    link$prime,
    main,
    main$prime,
    map,
    map$prime,
    mark,
    mark$prime,
    menu,
    menu$prime,
    menuitem,
    menuitem$prime,
    meta,
    meta$prime,
    meter,
    meter$prime,
    nav,
    nav$prime,
    noscript,
    noscript$prime,
    object,
    object$prime,
    ol,
    ol$prime,
    optgroup,
    optgroup$prime,
    option,
    option$prime,
    output,
    output$prime,
    p,
    p$prime,
    param,
    param$prime,
    picture,
    picture$prime,
    pre,
    pre$prime,
    progress,
    progress$prime,
    q,
    q$prime,
    rp,
    rp$prime,
    rt,
    rt$prime,
    ruby,
    ruby$prime,
    s,
    s$prime,
    samp,
    samp$prime,
    script,
    script$prime,
    section,
    section$prime,
    select,
    select$prime,
    small,
    small$prime,
    source,
    source$prime,
    span,
    span$prime,
    strong,
    strong$prime,
    style,
    style$prime,
    sub,
    sub$prime,
    summary,
    summary$prime,
    sup,
    sup$prime,
    table,
    table$prime,
    tbody,
    tbody$prime,
    td,
    td$prime,
    textarea,
    textarea$prime,
    tfoot,
    tfoot$prime,
    th,
    th$prime,
    thead,
    thead$prime,
    time,
    time$prime,
    title,
    title$prime,
    tr,
    tr$prime,
    track,
    track$prime,
    u,
    u$prime,
    ul,
    ul$prime,
    $$var as var,
    var$prime,
    video,
    video$prime,
    wbr,
    wbr$prime
};
