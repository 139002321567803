// Generated by purs version 0.15.15
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
var repeat = function (dictMonoid) {
    var append = Data_Semigroup.append(dictMonoid.Semigroup0());
    var repeat$prime = function ($copy_v) {
        return function ($copy_v1) {
            return function ($copy_v2) {
                var $tco_var_v = $copy_v;
                var $tco_var_v1 = $copy_v1;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(v, v1, v2) {
                    if (v2 < 1) {
                        $tco_done = true;
                        return v;
                    };
                    $tco_var_v = append(v)(v1);
                    $tco_var_v1 = v1;
                    $copy_v2 = v2 - 1 | 0;
                    return;
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_v, $tco_var_v1, $copy_v2);
                };
                return $tco_result;
            };
        };
    };
    return repeat$prime(Data_Monoid.mempty(dictMonoid));
};
var foldDigits = function (dictFoldable) {
    return Data_Foldable.foldl(dictFoldable)(function (acc) {
        return function (d) {
            return (acc * 10 | 0) + d | 0;
        };
    })(0);
};
export {
    foldDigits,
    repeat
};
