// Generated by purs version 0.15.15
var PureScript = /* #__PURE__ */ (function () {
    function PureScript() {

    };
    PureScript.value = new PureScript();
    return PureScript;
})();
var JavaScript = /* #__PURE__ */ (function () {
    function JavaScript() {

    };
    JavaScript.value = new JavaScript();
    return JavaScript;
})();
var CSharp = /* #__PURE__ */ (function () {
    function CSharp() {

    };
    CSharp.value = new CSharp();
    return CSharp;
})();
var Pseudo = /* #__PURE__ */ (function () {
    function Pseudo() {

    };
    Pseudo.value = new Pseudo();
    return Pseudo;
})();
var Haskell = /* #__PURE__ */ (function () {
    function Haskell() {

    };
    Haskell.value = new Haskell();
    return Haskell;
})();
var FlowParagraph = /* #__PURE__ */ (function () {
    function FlowParagraph(value0) {
        this.value0 = value0;
    };
    FlowParagraph.create = function (value0) {
        return new FlowParagraph(value0);
    };
    return FlowParagraph;
})();
var FlowMajorHeader = /* #__PURE__ */ (function () {
    function FlowMajorHeader(value0) {
        this.value0 = value0;
    };
    FlowMajorHeader.create = function (value0) {
        return new FlowMajorHeader(value0);
    };
    return FlowMajorHeader;
})();
var FlowMinorHeader = /* #__PURE__ */ (function () {
    function FlowMinorHeader(value0) {
        this.value0 = value0;
    };
    FlowMinorHeader.create = function (value0) {
        return new FlowMinorHeader(value0);
    };
    return FlowMinorHeader;
})();
var FlowSection = /* #__PURE__ */ (function () {
    function FlowSection(value0) {
        this.value0 = value0;
    };
    FlowSection.create = function (value0) {
        return new FlowSection(value0);
    };
    return FlowSection;
})();
var FlowLink = /* #__PURE__ */ (function () {
    function FlowLink(value0) {
        this.value0 = value0;
    };
    FlowLink.create = function (value0) {
        return new FlowLink(value0);
    };
    return FlowLink;
})();
var FlowQuote = /* #__PURE__ */ (function () {
    function FlowQuote(value0) {
        this.value0 = value0;
    };
    FlowQuote.create = function (value0) {
        return new FlowQuote(value0);
    };
    return FlowQuote;
})();
var FlowBullets = /* #__PURE__ */ (function () {
    function FlowBullets(value0) {
        this.value0 = value0;
    };
    FlowBullets.create = function (value0) {
        return new FlowBullets(value0);
    };
    return FlowBullets;
})();
var FlowSourceCode = /* #__PURE__ */ (function () {
    function FlowSourceCode(value0) {
        this.value0 = value0;
    };
    FlowSourceCode.create = function (value0) {
        return new FlowSourceCode(value0);
    };
    return FlowSourceCode;
})();
var FlowImage = /* #__PURE__ */ (function () {
    function FlowImage(value0) {
        this.value0 = value0;
    };
    FlowImage.create = function (value0) {
        return new FlowImage(value0);
    };
    return FlowImage;
})();
var FlowYouTube = /* #__PURE__ */ (function () {
    function FlowYouTube(value0) {
        this.value0 = value0;
    };
    FlowYouTube.create = function (value0) {
        return new FlowYouTube(value0);
    };
    return FlowYouTube;
})();
var FlowYouTubeShorts = /* #__PURE__ */ (function () {
    function FlowYouTubeShorts(value0) {
        this.value0 = value0;
    };
    FlowYouTubeShorts.create = function (value0) {
        return new FlowYouTubeShorts(value0);
    };
    return FlowYouTubeShorts;
})();
var Article = function (x) {
    return x;
};
var SingleArticle = /* #__PURE__ */ (function () {
    function SingleArticle(value0) {
        this.value0 = value0;
    };
    SingleArticle.create = function (value0) {
        return new SingleArticle(value0);
    };
    return SingleArticle;
})();
var ListOfArticles = /* #__PURE__ */ (function () {
    function ListOfArticles(value0) {
        this.value0 = value0;
    };
    ListOfArticles.create = function (value0) {
        return new ListOfArticles(value0);
    };
    return ListOfArticles;
})();
var Stream = /* #__PURE__ */ (function () {
    function Stream(value0) {
        this.value0 = value0;
    };
    Stream.create = function (value0) {
        return new Stream(value0);
    };
    return Stream;
})();
var CV = /* #__PURE__ */ (function () {
    function CV() {

    };
    CV.value = new CV();
    return CV;
})();
var Contact = /* #__PURE__ */ (function () {
    function Contact() {

    };
    Contact.value = new Contact();
    return Contact;
})();
var Guestbook = /* #__PURE__ */ (function () {
    function Guestbook() {

    };
    Guestbook.value = new Guestbook();
    return Guestbook;
})();
var NotFound = /* #__PURE__ */ (function () {
    function NotFound() {

    };
    NotFound.value = new NotFound();
    return NotFound;
})();
var GotoPage = /* #__PURE__ */ (function () {
    function GotoPage(value0) {
        this.value0 = value0;
    };
    GotoPage.create = function (value0) {
        return new GotoPage(value0);
    };
    return GotoPage;
})();
export {
    SingleArticle,
    ListOfArticles,
    Stream,
    CV,
    Contact,
    Guestbook,
    NotFound,
    GotoPage,
    Article,
    PureScript,
    JavaScript,
    CSharp,
    Pseudo,
    Haskell,
    FlowParagraph,
    FlowMajorHeader,
    FlowMinorHeader,
    FlowSection,
    FlowLink,
    FlowQuote,
    FlowBullets,
    FlowSourceCode,
    FlowImage,
    FlowYouTube,
    FlowYouTubeShorts
};
